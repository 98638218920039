import React, { useState, useEffect } from 'react';
import {
  Box,
  Image,
} from 'grommet'
import useWindowSize from '../../utils/WindowSize';
import { animated, useSpring } from "react-spring";
import ComponentVideo from "./Video";

const Hero = (props) => {
  const { content, title } = props;
  //console.log(content)
  const [imageLoaded, setImageLoaded] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const listener = e => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  const calcOpacity = () => {
    return 1 - (scrollY >= 300 ? '1' : scrollY / 300)
  }

  const calcScale = () => {
    return 1 - (scrollY / 2000)
  }

  const calcOffset = () => {
    return (scrollY / 2)
  }

  const moveIt = useSpring({
    opacity: calcOpacity(),
    transform: `translateY(${calcOffset()}px)`
  });

  // window size
  const size = useWindowSize();

  const hasImage = () => {

  }

  const hasVideo = () => {
      if (content.fields.hasOwnProperty("youtubeVideoId") && content.fields.youtubeVideoId ){
        return content.fields.youtubeVideoId;
      } else {
        return false;
      }
  }

  const hasParrallaxImage = () => {

  }

  const disableZoom = () => {
    if (content.fields.hasOwnProperty("disableZoom")){
      return content.fields.disableZoom;
    } else {
      return false;
    }
  }

  const getZoomAnimationClass = () => {
    if (disableZoom()){
      return " transitionFadeIn ";
    } else {
      return " transitionFadeInScale "
    }
  }

  const showTitle = () => {
    if (content.fields.hasOwnProperty("showTitle") && content.fields.showTitle){
      return (
        <h1 className="">{content.fields.title}</h1>
      )
    } else {
      return null;
    }
  }

  const handleImageLoaded = () => {
    setImageLoaded("true");
  }

  const renderImages = () => {
    var video = hasVideo();
    if (video){
        return (
            <div
              className="video"
              style={{
                position: "relative",
                paddingBottom: "56.25%" /* 16:9 */,
                paddingTop: 25,
                height: "100%",
                width: "100%"
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${content.fields.youtubeVideoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={content.fields.title}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
              />
            </div>
        )
    }
    if (
      content.fields.hasOwnProperty("image")
      && content.fields.image
      && content.fields.hasOwnProperty("parrallaxImage")
      && content.fields.parrallaxImage
    ){
      // Parrallax images
      var desktop = <div className="parrallaxContainer">
          <animated.img
            style={moveIt}
            className={ imageLoaded ? "heroImage heroImageDesktop heroImageDesktopParrallaxTop" : "heroImage heroImageDesktop heroImageDesktopParrallaxTop" }
            src={content.fields.parrallaxImage.fields.file.url}
            onLoad={handleImageLoaded.bind(this)}
          />
          <img
            className={ imageLoaded ? "transitionFadeIn heroImage heroImageDesktop heroImageDesktopParrallaxBottom" : "heroImageLoading heroImage heroImageDesktop heroImageDesktopParrallaxBottom" }
            src={content.fields.image.fields.file.url}
            onLoad={handleImageLoaded.bind(this)}
          />
        </div>
    } else if (content.fields.hasOwnProperty("image") && content.fields.image){
      var desktop = <img
                className={ imageLoaded ? getZoomAnimationClass() + " heroImage heroImageDesktop" : "heroImageLoading heroImage heroImageDesktop" }
                src={content.fields.image.fields.file.url}
                onLoad={handleImageLoaded.bind(this)}
            />
    } else {
      var desktop = null
    }
    // Mobile image
    if (content.fields.hasOwnProperty("imageMobile") && content.fields.imageMobile){
        if (
          content.fields.hasOwnProperty("imageMobile")
          && content.fields.imageMobile
          && content.fields.imageMobile.hasOwnProperty("fields")
          && content.fields.imageMobile.fields
          && content.fields.imageMobile.fields.hasOwnProperty("file")
          && content.fields.imageMobile.fields.file
          && content.fields.hasOwnProperty("parrallaxImage")
          && content.fields.parrallaxImage
        ){
            // Mobile Parrallax images
            var mobile = <div className="parrallaxContainer">
                  <animated.img
                    style={moveIt}
                    className={ imageLoaded ? "heroImage heroImageMobile heroImageDesktopParrallaxTop" : "heroImage heroImageMobile heroImageDesktopParrallaxTop" }
                    src={content.fields.parrallaxImage.fields.file.url}
                    onLoad={handleImageLoaded.bind(this)}
                  />
                <img
                    className={ imageLoaded ? "transitionFadeIn heroImage heroImageMobile" : "heroImageLoading heroImage heroImageMobile" }
                    src={content.fields.imageMobile.fields.file.url}
                    onLoad={handleImageLoaded.bind(this)}
                />
            </div>
        } else {
          if (
              content.fields.hasOwnProperty("imageMobile")
              && content.fields.imageMobile
              && content.fields.imageMobile.hasOwnProperty("fields")
              && content.fields.imageMobile.fields
              && content.fields.imageMobile.fields.hasOwnProperty("file")
              && content.fields.imageMobile.fields.file
          ) {
            var mobile = <img
                className={ imageLoaded ? getZoomAnimationClass() + "heroImage heroImageMobile" : "heroImageLoading heroImage heroImageMobile" }
                src={content.fields.imageMobile.fields.file.url}
                onLoad={handleImageLoaded.bind(this)}
            />
          } else {
            var mobile = <img
                className={ imageLoaded ? getZoomAnimationClass() + "heroImage heroImageMobile" : "heroImageLoading heroImage heroImageMobile" }
                src={content.fields.image.fields.file.url}
                onLoad={handleImageLoaded.bind(this)}
            />
          }
        }
    } else {
      if (content.fields.hasOwnProperty("image") && content.fields.image){
        // Use desktop image if we have one
        var mobile = <img
                  className={ imageLoaded ? getZoomAnimationClass() + "heroImage heroImageMobile" : "heroImageLoading heroImage heroImageMobile" }
                  src={content.fields.image.fields.file.url}
                  onLoad={handleImageLoaded.bind(this)}
              />
      } else {
        var mobile = null
      }

    }
    return (
      <>
        {/**<h1>{calcOpacity()}</h1>**/}
        {desktop}
        {mobile}
      </>
    )
  }

  const calcHeight = () => {
    if (hasVideo()){
        // Have a video so calculate height as 16/9 ratio
        var windowWidth = size.width
        return ((windowWidth / 1.777777778)) + "px";
    }
    // If we have a hero image then calculate the height based on 100% width
    if (content.fields.hasOwnProperty("image") && content.fields.image){
      var imageHeight = content.fields.image.fields.file.details.image.height
      var imageWidth = content.fields.image.fields.file.details.image.width
      var imageRatio = imageHeight / imageWidth
      var windowWidth = size.width
      //alert(windowWidth * imageRatio + "px")
      return (windowWidth * imageRatio - 20) + "px"
    } else {
      return "inherit"
    }
  }

  const calcHeightMobile = () => {
      if (hasVideo()){
          // Have a video so calculate height as 16/9 ratio
          var windowWidth = size.width
          return ((windowWidth / 1.777777778)) + "px";
      }
    // If we have a hero image then calculate the height based on 100% width
    if (
      content.fields.hasOwnProperty("imageMobile")
      && content.fields.imageMobile
      && content.fields.imageMobile.hasOwnProperty("fields")
      && content.fields.imageMobile.fields
      && content.fields.imageMobile.fields.hasOwnProperty("file")
      && content.fields.imageMobile.fields.file
    ){
      var imageHeight = content.fields.imageMobile.fields.file.details.image.height
      var imageWidth = content.fields.imageMobile.fields.file.details.image.width
      var imageRatio = imageHeight / imageWidth
      var windowWidth = size.width
      //alert(windowWidth * imageRatio + "px")
      return (windowWidth * imageRatio - 20) + "px"
    } else {
      return calcHeight()
    }
  }

  if (title) {
    return (
      <Box
        className="hero heroDefault"
      >
        <h1 className="transitionSlideUp">{title}</h1>
      </Box>
    )
  } else {
    return (
        <Box
          className="hero heroHeightCalculated"
          height={size.width > 767 ? calcHeight() : calcHeightMobile()}
          //height="medium"
          //background={'url(' + content.fields.image.fields.file.url + ')'}
        >
          {showTitle()}
          {renderImages()}
        </Box>
    )
  }
}

export default Hero;
