import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
//import {Helmet} from "react-helmet";
import API from '../api';
import Settings from '../constants/Settings';

import {
  Box,
} from 'grommet';

import Content from '../utils/RenderContent';
//import Analytics from '../utils/Analytics';
import RenderHelmet from '../utils/RenderHelmet';
import PageWrap from "../components/PageWrap";
//import NotFound from '../components/NotFound';

const Test = (props) => {
    const { match, t, i18n } = props;
    const [content, setContent] = useState(false);

    // Similar to componentDidMount and componentDidUpdate:
    // Empty array as second param so code only runs once
    // https://css-tricks.com/run-useeffect-only-once/
    useEffect(() => {
      //Analytics.TrackPageView(window.location.pathname)
      loadHomeContent()
    }, [loadHomeContent]);

    const loadHomeContent = () => {
      API.getPageBySlug(i18n, 'test-page').then( (res) => {
        var inlineContent = res.items[0];
        //var inlineContent = API.inlineContent(res);
        setContent(inlineContent);
        setTimeout(checkForHash, 500);
      })
      .catch(console.error)
    }

    const checkForHash = () => {
      if (props.location.hash){
        //alert(props.location.hash)
        var hashElement = document.getElementById(props.location.hash.replace('#',''))
        hashElement.scrollIntoView({ behavior: 'smooth' })
      }
    }

    // check for pages not in Languages
    // Cannot use replaceAll in older browsers
    //const path = match.path.replaceAll("/","")
    const path = match.path.replace(/\//g, '');
    if (!Settings.languagesCatchall.includes(path)){
      //return <NotFound />
    }

    return (
      <Box id="page">
        <RenderHelmet content={content} match={match} t={t} i18n={i18n} />
        <PageWrap content={content}>
          {/**<p>i18n lang: {i18n.language} / match.params.locale: {JSON.stringify(match)}</p>**/}
          {/**JSON.stringify(props.location)**/}
          <Content content={content} match={match} t={t} i18n={i18n} />
        </PageWrap>
      </Box>
    );
}

const PageTranslated = withTranslation("translations")(Test);
export default PageTranslated
