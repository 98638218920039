import React, { useState, useEffect, useContext } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
  Box,
  Button,
  Image,
  Layer,
  Markdown,
  ResponsiveContext,
  Text
} from 'grommet'
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import RenderRichText from '../../utils/RenderRichText';
import API from '../../api';
import { shuffleArray, calcRowBasis, scrollToID } from '../../utils/Misc';
import Settings from '../../constants/Settings';
import { CenterModal, ModalTitle, ModalCloseTarget } from 'react-spring-modal';
import FadeInInductee from '../utility/FadeInInductee';
import YoutubeEmbed from './YoutubeEmbed';
import Analytics from '../../utils/Analytics';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import hof_logo from '../../assets/images/HOF-LOGO-transparent-white.png';
import hof_logo_2x from '../../assets/images/HOF-LOGO-transparent-white@2x.png';
import card_bg from '../../assets/images/inductee-card-bg.jpg';

const ComponentInductees = (props) => {
  const { field, match, t, i18n } = props;
  const size = React.useContext(ResponsiveContext);
  const [categories, setCategories] = useState([]);
  const [popup, setPopup] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  // Need to work what year we are using
  var YEAR = "inductee2023"
  if (field.hasOwnProperty("fields") && field.fields){
    if (field.fields.hasOwnProperty("year")){
      YEAR = "inductee" + field.fields.year
    }
  }

  const loadCategories = () => {
    // Get Categories
    API.getCategories(i18n).then( (res) => {
      setCategories(res.items)
    })
    .catch(console.error)
  }

  const openPopup = (item) => {
    Analytics.TrackPageView(item.fields.name, "/Inductee/" + _.kebabCase(item.fields.name));
    setPopup(item);
    setPopupOpen(true);
  }

  const closePopup = (item) => {
    setPopupOpen(false);
    setTimeout(() => { setPopup(item)}, 500)
  }

  // Similar to componentDidMount and componentDidUpdate:
  // Empty array as second param so ocde only runs once
  // https://css-tricks.com/run-useeffect-only-once/
  useEffect(() => {
    loadCategories();
  }, []);

  function renderTitle() {
    if (field.fields.hasOwnProperty('title') && field.fields.title){
      return (
        <h2>
          {field.fields.title}
        </h2>
      )
    } else {
      return null
    }
  }

  function renderText() {
    if (field.fields.hasOwnProperty('text') && field.fields.text){
      return (
        <Text size={checkBig()} textAlign={checkCentered()} dangerouslySetInnerHTML={{ __html: RenderRichText(field.fields.text) }} />
      )
    } else {
      return null
    }
  }

  function checkBig() {
    if (field.fields.hasOwnProperty('big') && field.fields.big){
      return "xlarge"
    } else {
      return "medium"
    }
  }

  function checkCentered() {
    if (field.fields.hasOwnProperty('centered') && field.fields.centered){
      return "center"
    } else {
      return "start"
    }
  }

  function checkCenteredPadding() {
    if (field.fields.hasOwnProperty('centered') && field.fields.centered){
      return "large"
    } else {
      return "none"
    }
  }

  const renderCategoryImage = (category, float) => {
    if (
      category.fields.hasOwnProperty('imageSmall')
      && category.fields.image
    ){
      return (
        <img
          className={float ? "categoryImage categoryImageFloat" : "categoryImage"}
          //fit="contain"
          //fill={false}
          src={category.fields.imageSmall.fields.file.url}
        />
      )
    } else {
      return (
        <p>No category image</p>
      )
    }
  }

  const renderInducteeImage = (category) => {
    if (
      category.fields.hasOwnProperty(YEAR)
      && category.fields[YEAR]
      && category.fields[YEAR].fields.hasOwnProperty('image')
    ){
      return (
        <img
          className="inducteeImage"
          src={category.fields[YEAR].fields.image.fields.file.url}
        />
      )
    } else {
      return (
        <p>No inductee image</p>
      )
    }
  }

  const renderInducteeName = (category) => {
    if (
      category.fields.hasOwnProperty(YEAR)
      && category.fields[YEAR]
      && category.fields[YEAR].fields.hasOwnProperty('name')
    ){
      return (
        <h3>{category.fields[YEAR].fields.name}</h3>
      )
    } else {
      return null;
    }
  }

  const renderInducteeSpecs = (category) => {
    if (
      category.fields.hasOwnProperty(YEAR)
      && category.fields[YEAR]
      && category.fields[YEAR].fields.hasOwnProperty('specs')
    ){
      console.log("specs")
      console.log(category.fields[YEAR].fields.specs)
      return (
        <Box
          className="specs"
          direction="row"
          justify="stretch"
        >
          { category.fields[YEAR].fields.specs.map( (spec, index) => {
            return (
              <Box className="spec" flex="grow" key={index}>
                <h4 className="specTitle">{spec.fields.title}</h4>
                <div className="specValue">{spec.fields.value}</div>
              </Box>
            )
          })}
        </Box>
      )
    } else {
      return null
    }
  }

  const renderInducteeShortDescription = (category) => {
    if (
      category.fields.hasOwnProperty(YEAR)
      && category.fields[YEAR]
      && category.fields[YEAR].fields.hasOwnProperty('shortDescription')
    ){
      return (
        <p className="shortDescription">{category.fields[YEAR].fields.shortDescription}</p>
      )
    } else {
      return null
    }
  }

  const renderInducteeLongDescription = (category) => {
    if (
      category.fields.hasOwnProperty(YEAR)
      && category.fields[YEAR]
      && category.fields[YEAR].fields.hasOwnProperty('longDescription')
    ){
      return (
        <Box className="longDescription">
          <Markdown
            components={{
              "p": {
                "component": "Paragraph",
                "props": {"size": "medium"}
              }
            }}
          >{category.fields[YEAR].fields.longDescription }</Markdown>
        </Box>
      )
    } else {
      return null
    }
  }

  const renderInducteeVideo = (category) => {
    if (
      category.fields.hasOwnProperty(YEAR)
      && category.fields[YEAR]
      && category.fields[YEAR].fields.hasOwnProperty('videoId')
      && category.fields[YEAR].fields.videoId
    ){
      return (
        <Box className="video">
          <YoutubeEmbed embedId={category.fields[YEAR].fields.videoId} />
        </Box>
      )
    } else {
      return null
    }
  }

  const renderInducteeCTA = (category) => {
    if (
      category.fields.hasOwnProperty(YEAR)
      && category.fields[YEAR]
      && category.fields[YEAR].fields.hasOwnProperty('cta01')
      && category.fields[YEAR].fields.cta01
      && category.fields[YEAR].fields.hasOwnProperty('ctaLink')
      && category.fields[YEAR].fields.ctaLink
    ){
      return (
        <Box className="footer" pad="medium" justify="center" align="center">
          <Button
            secondary
            href={category.fields[YEAR].fields.ctaLink}
            label={category.fields[YEAR].fields.cta01}
          />
        </Box>
      )
    } else {
      return null
    }
  }

  const renderPopup = () => {
    if (!popup){
      return null
    } else {
      return (
        <Box className="popupInductee">
          <Box flex={{grow: 0,shrink: 1}} className="header">
            {renderCategoryImage(popup)}
            {renderInducteeImage(popup)}
            <div className="inducteeLabel">{t('inductee')}</div>
            {renderInducteeName(popup)}
          </Box>
          <Box flex={{grow: 1, shrink: 0}} className="body">
            {renderInducteeShortDescription(popup)}
            {renderInducteeSpecs(popup)}
            {renderInducteeVideo(popup)}
            {renderInducteeLongDescription(popup)}
          </Box>
            {renderInducteeCTA(popup)}
        </Box>
      )
    }
  }

  const calcBasis = () => {
    return calcRowBasis(size)
  }

  return (
      <Box className="inductees" id="inductees" pad="large">
      {/**<Text>grommet size: {size} / basis: {calcBasis()}</Text>**/}
        <Box className="header">
          {renderTitle()}
          {renderText()}
        </Box>

        <CenterModal
          isOpen={popupOpen}
          onDismiss={() => closePopup(false)}
          contentTransition={{
            from: { background: 'black', transform: 'translateY(-100%)' },
            enter: { background: 'black', transform: 'translateY(0)' },
            leave: { background: 'black', transform: 'translateY(-100%)' }
          }}
          //contentProps={{ style: staticModalStyles }}
        >
          <ModalCloseTarget>
            <button className="modalClose"><FontAwesomeIcon icon={faTimesCircle} /></button>
          </ModalCloseTarget>
            {renderPopup()}
        </CenterModal>

        <Box
          direction="row"
          wrap={true}
          //gap="medium"
          className="inducteesContainer"
        >

          { categories.map( (category, index) => {
            return (
              <FadeInInductee
                key={index}
                delay={index}
                calcBasis={calcBasis}
                onClick={() => {openPopup(category)}}
              >
                {renderCategoryImage(category, true)}
                {renderInducteeImage(category)}
                <Box className="textWrapper">
                  {renderInducteeName(category)}
                  {renderInducteeShortDescription(category)}
                </Box>

                <Box className="buttonWrapper inducteeButtonContainer">
                  <Button
                    secondary
                    label={t("Learn more")}
                    fill="horizontal"
                    focusIndicator={false}
                    hoverIndicator={false}
                  />
                </Box>
              </FadeInInductee>
            )
          })}

              <FadeInInductee
                calcBasis={calcBasis}
                background={`url(${card_bg})`}
                onClick={() => {
                  if (!Settings.SHOW_CATEGORIES){
                    var hashElement = document.getElementById("newsletter")
                    hashElement.scrollIntoView({ behavior: 'smooth' })
                  }
                }}
                key={5}
                delay={5}
              >

                <Image
                  className="categoryImage"
                  fit="contain"
                  fill={false}
                  src={hof_logo}
                  srcSet={`${hof_logo} 1x, ${hof_logo_2x} 2x, `}
                  style={{flexGrow: 1, flexBasis: "75%"}}
                />

                { YEAR == "inductee2023" ?
                  <h3 className="bannerHeading">{t("View Class of 2022")}</h3>
                  : <h3 className="bannerHeading">{t("View Class of 2023")}</h3>
                }


                <Box
                  className="buttonWrapper inducteeButtonContainer"
                  flex={false}
                >
                { YEAR !== "inductee2023" &&
                    <Link
                      to={`/${i18n.language}#inductees`}
                      onClick={() => {
                        scrollToID("inductees")
                      }}
                      style={{display: "block", textAlign: "center"}}
                    >
                      <Button primary label={t("Learn more")} fill="horizontal" hoverIndicator={false} focusIndicator={false} />
                    </Link>
                }
                { YEAR === "inductee2023" &&
                  <Link
                    to={`/${i18n.language}/class-of-2022`}
                    style={{display: "block", textalign: "center"}}
                  >
                    <Button primary label={t("learn more")} fill="horizontal" hoverindicator={false} focusindicator={false} />
                  </Link>
                }
                </Box>
                </FadeInInductee>

        </Box>
      </Box>
  )
}

export default ComponentInductees;
