import _ from "lodash";

const Analytics = {};

Analytics.createGlobalQueue = () => {
  if (!window.hasOwnProperty("ga_queue")){
    window.ga_queue = [];
  }
}

Analytics.clearQueue = () => {
  window.ga_queue = [];
}

Analytics.processQueue = () => {
  var deduped = _.uniqBy(window.ga_queue, function (e) {
    return e.page;
  });
  Analytics.clearQueue();

  // Send page views
  _.map(deduped, (o) => {
    if (
      o.page === window.location.pathname
      || _.startsWith(o.page, '/CommitteeMember/')
      || _.startsWith(o.page, '/Inductee/')
      || _.startsWith(o.page, '/Category/')
      || _.startsWith(o.page, '/Nominee/')
    ){
      // avoid page views being sent twice by react page re-renders
      //console.log("Send pageview")
      //console.log(o)
      // Get correct tracker UA
      if (window.ga.getAll().length > 0){
        var tracker = window.ga.getAll()[0];
        tracker.set('location', "https://photoandvideokithalloffame.com" + o.page);
        tracker.set('page', o.page);
        if (o.title){
          tracker.set('title', o.title);
        }
        tracker.send('pageview');
      }
	  // GTM
	  console.log("Push to datalayer")
	  console.log(o);
	  window.dataLayer = window.dataLayer || [];
	  var pageTitle = "unknown";
      if (o.title){
        pageTitle = o.title;
      }
      window.dataLayer.push({
       'event' : 'pageview',
       'pagePath' : o.page,
       'pageTitle' : pageTitle 
     });		  
    }
  })

}

Analytics.TrackPageView = (title = false, page = window.location.pathname) => {
  Analytics.createGlobalQueue();
  if (window.hasOwnProperty('ga')){
    if (title){
      window.ga_queue.push({
        page: page,
        title: title
      })
    } else {
      window.ga_queue.push({
        page: window.location.pathname,
        title: false
      })
    }
    setTimeout(Analytics.processQueue, 3000)
  }
}

Analytics.TrackCustom = (data) => {
  Analytics.createGlobalQueue();
  if (window.hasOwnProperty('dataLayer') && data){
      console.log("analytics custom");
      console.log(data);
      window.dataLayer.push(data);
      //window.dataLayer.push({
      // 'event' : 'pageview',
      // 'pagePath' : o.page,
      // 'pageTitle' : pageTitle 
     //});		  
  } else {
    //console.log("no datalayer OR event data");
  }
}

export default Analytics;
