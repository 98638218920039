// Start Imports
import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import API from '../api';
import {
  Box
} from 'grommet';
import Content from '../utils/RenderContent';
import RenderHelmet from '../utils/RenderHelmet';
import PageWrap from "../components/PageWrap";
// End Imports

const Class2022 = (props) => {
    //const { t, i18n } = props;
    const { match, t, i18n } = props;
    const [content, setContent] = useState(false);

    // Similar to componentDidMount and componentDidUpdate:
    // Empty array as second param so ocde only runs once
    // https://css-tricks.com/run-useeffect-only-once/
    useEffect(() => {
      loadHomeContent()
    }, []);

    const loadHomeContent = () => {
      API.getPageBySlug(i18n, 'class-of-2022').then( (res) => {
        var inlineContent = res.items[0];
        //var inlineContent = API.inlineContent(res);
        console.log(inlineContent);
        setContent(inlineContent);
      })
      .catch(console.error)
    }

    return (
      <Box id="page">
        <RenderHelmet content={content} match={match} t={t} i18n={i18n} />
        <PageWrap content={content}>
          <Content content={content} match={match} t={t} i18n={i18n} />
        </PageWrap>
      </Box>
    );
}

const PageTranslated = withTranslation("translations")(Class2022);
export default PageTranslated
