import React, { useState } from 'react';
import { connect } from 'react-redux'
import { animated, useSpring } from "react-spring";
import { parseQueryString } from "../utils/Misc";
import {
  Box,
  Button,
  Image,
} from 'grommet';
import _ from 'lodash';
import Analytics from '../utils/Analytics';

// #TODO - add image and colour in contentful and use as category background.

const CategoryComponent = (props) => {
  const { t, i18n, match, category, addVote, votes, selected } = props;
  const [isToggled, setIsToggled] = useState(false);

  // #TODO - use reactspring trails e.g. https://codesandbox.io/s/49jsc?file=/src/index.js
  const fade = useSpring({
    opacity: isToggled
      ? '1'
      : '0',
      //color: isToggled ? '#000' : 'red',
      //transform: isToggled
      //  ? 'translate3d(0, 30px, 0)'
      //  : 'translate3d(0, 30px, 0)',
      //fontSize: isToggled ? '2rem' : '3rem',
    });

  // Toggle display of products
  const toggle = () => {
    setIsToggled(!isToggled)
  }

  const checkIfSelected = () => {
    // Check if this category is currently selected
    if (selected){
      if (props.selected.sys.id === category.sys.id){
        return true;
      }
    }
    return false;
  }

  const checkIfVoteSelected = () => {
    // Check if vote has been selected for this category
    if (votes.hasOwnProperty(category.fields.id) && votes[category.fields.id]){
      return true
    }
    return false
  }

  const getCategoryClasses = () => {
    // Collect up classes for category box (selected, voted etc)
    var s2 = checkIfSelected() ? 'categorySelected' : '';
    var s3 = checkIfVoteSelected() ? 'categoryVoteSelected' : '';
    return ("category" + " " +  s2 + " " + s3)
  }

  const renderBackground = () => {
    if (category.fields.hasOwnProperty("backgroundColor") && category.fields.backgroundColor){
      return (
        <div
            className={"backgroundOverlayInner"}
            style={{
              background: category.fields.backgroundColor,
              opacity: 1,
            }}
        >
        </div>
      )
    } else {
      return (
        <div
            className={"backgroundOverlayInner " + (category.fields.name.toLowerCase() === "classic" ? "classBackgroundFix" : "")}
            style={{ backgroundImage: `url(${category.fields.image.fields.file.url})` }}
        >
        </div>
      )
    }
  }

  const handleClick = () => {
      Analytics.TrackPageView(category.fields.name, "/Category/" + _.kebabCase(category.fields.name));
  }

  return (
    <Box
      className={getCategoryClasses()}
      onClick={handleClick}
    >
        <div
            className="backgroundOverlay"
        >
            <div className="voted">
              {t('voted')}
            </div>
            {renderBackground()}
        </div>
        <Box
          className="categoryContent"
          focusIndicator={false}
          height="small"
          flex
          //margin={{"bottom":"medium"}}
          key={category.sys.id}
          onClick={() => props.callback(category)}
        >
          <Box
            className="categoryContentInner"
            flex="grow"
            style={{overflow: "visible"}}
          >
            <Box
              direction="row"
              style={{overflow: "visible"}}
            >
              <Box className="categoryImageContainer" flex="grow" height="small" style={{overflow: "visible"}}>
                <Image
                  className="categoryImage"
                  fit="contain"
                  style={{overflow: "visible"}}
                  src={category.fields.image.fields.file.url}
                />
              </Box>
            </Box>
            { checkIfSelected() ?
              <div className="showButtonContainer">
                <Button primary label={t('nominees')} focusIndicator={false} />
                <div className="showButtonIndicator"></div>
              </div>
              :
              <div className="showButtonContainer">
                <Button secondary label={t('nominees')} focusIndicator={false} />
                <div className="showButtonIndicator"></div>
              </div>
            }
          </Box>

         </Box>
    </Box>
  )
}

// Map redux state to props (remember to export below with this function)
const mapStateToProps = (state) => {
    return {
      votes: state.cart.votes
    }
}

//export default CategoryComponent;
export default connect(mapStateToProps)(CategoryComponent)
