import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    "en-US": {
      translations: {
        // Nav
        "Home" : "Home",
        "Contact" : "Contact",
        "2023 Nominees" : "2023 Nominees",
        "2021 Nominees" : "2021 Nominees",
        "Class of 2020" : "Class of 2020",
        "Class of 2021" : "Class of 2021",
        "Class of 2022" : "Class of 2022",
        "Class of 2023" : "Class of 2023",
        "View Class of 2020" : "View Class of 2020",
        "View Class of 2021" : "View Class of 2021",
        "View Class of 2022" : "View Class of 2022",
        "View Class of 2023" : "View Class of 2023",
        "Selection Committee" : "Nominating Committee",
        "Press & Media" : "Press & Media",
        "About MPB" : "About MPB",
        "Privacy Policy" : "Privacy Policy",
        "Terms & Conditions" : "Terms & Conditions",
        "Hello" : "Howdy",
        // general
        "View the class of 2020" : "View the class of 2020",
        "View the class of 2022" : "View the class of 2022",
        "Learn more" : "Learn more",
        "Submitting" : "Submitting",
        "Try again" : "Try again",
        "We do apologise but something seems to have gone wrong" : "We do apologise but something seems to have gone wrong",
        // cookies
        "cookie_msg" : "This website used cookies to give you the best experience, please choose the cookies you are happy to accept and submit your preferences",
        "Accept all" : "Accept all",
        "Manage cookies" : "Manage cookies",
        "Decline" : "Decline",
        "Necessary" : "Necessary",
        "Preferences" : "Preferences",
        "Statistics" : "Statistics",
        "Marketing" : "Marketing",
        // Countdown
        "Voting opens in" : "Voting opens in",
        "days" : "days",
        "hours" : "hours",
        "minutes" : "minutes",
        "seconds" : "seconds",
        // categories / class of 2020
        "Cast your vote" : "Cast your vote",
        "voted" : "voted",
        "nominees" : "nominees",
        "vote for" : "vote for",
        "selected" : "selected",
        "details" : "details",
        "show" : "show",
        "2020 inductees" : "2020 inductees",
        "learn more" : "learn more",
        "inductee" : "inductee",
        // Newsletter
        "sign up" : "subscribe",
        "your email" : "Your email",
        "select your country" : "Select your country",
        "Select your country" : "Select your country",
        "Please enter your email" : "Please enter your email",
        "Please select your country" : "Please select your country",
        "Please agree to our privacy policy" : "Please agree to our privacy policy",
        "Please agree to our" : "Please agree to our",
        "I have read and accepted the" : "I have read and accepted the",
        "gelesen und akzeptiere diese" : "",
        // Language Selector
        "United States" : "United States",
        "United Kingdom" : "United Kingdom",
        "Canada" : "Canada",
        "South America" : "South America",
        "Other" : "Other",
        "usa" : "usa",
        "uk" : "uk",
        "germany" : "germany",
        "Germany" : "Germany",
        "france" : "france",
        "France" : "France",
        "eu" : "eu",
        // Cart
        "out of" : "out of",
        "votes" : "votes",
        "vote" : "vote",
        "edit votes" : "Edit Votes",
        "cast ballot" : "cast ballot",
        "Your Votes" : "Your Votes",
        "Last chance to cast your remaining votes" : "Last chance to select nominees in remaining categories",
        "You’re voting for the" : "You’re voting for the",
        "Keep Voting" : "Keep Voting",
        "Submit Votes" : "Submit Votes",
        "Submit your votes" : "Provide your information to cast your ballot",
        "Oops, something went wrong" : "Oops, something went wrong",
        "Your votes have been recorded" : "Thank you, You have cast your votes for the Class of 2023.",
        "Submitting your votes" : "Submitting your votes",
        "First Name" : "First Name",
        "Second Name" : "Second Name",
        "Email Address" : "Email Address",
        "Please enter your first name" : "Please enter your first name",
        "Please enter your second name" : "Please enter your second name",
        "Please enter your email" : "Please enter your email",
        "Please enter a valid email" : "Please enter a valid email",
        "Please select your country" : "Please select your country",
        "Please agree to our terms and conditions" : "Please agree to our terms and conditions",
        "Enter Sweepstakes" : "Enter to win a Hasselblad 500CM and more",
        "Receive News and Updates from MPB?" : "Stay up to date about The Photo and Video Kit Hall of Fame, get regular offers on used kit from MPB and read the latest content every week.",
        "By clicking submit you are agreeing to our" : "By clicking submit you are agreeing to our",
        "Edit Votes" : "Edit Votes",
        "submit" : "submit",
        "Return to the site" : "Back to home",
        "Explore MPB" : "Explore MPB",
        "You voted successfully on" : "You voted successfully on",
        "Cast your vote for the Class of 2021" : "Voting has closed for the Class of 2023. Learn more about the 2023 nominees",
        "vote now" : "vote now",
        "sweepstakes_text" : "Enter for a chance to win a used Sony A7 III from MPB. Everyone is invited to vote in the Photo and Video Kit Hall of Fame, but only United States legal residents can enter to win.",
        "sweepstakes_text_eu" : "Enter for a chance to win a used Sony A7 III from MPB. Everyone is invited to vote in the The Photo and Video Hall of Fame, but only European residents (excluding France and Germany) can enter to win.",
        "sweepstakes_hyperlink" : "See Terms and Conditions ",
        "I have read and accepted the" : "I have read and accepted the",
        "Share on social media" : "Share on social media",
        "I voted for the best photo and video kit" : "I voted for the best photo and video kit of the digital age at photoandvideokithalloffame.com",
      }
    },
    "en-GB": {
      // en-GB - Not currently used
      translations: {
        // Nav
        "Home" : "Home",
        "Contact" : "Contact",
        "2021 Nominees" : "2021 Nominees",
        "2023 Nominees" : "2023 Nominees",
        "Class of 2020" : "Class of 2020",
        "Class of 2021" : "Class of 2021",
        "Class of 2022" : "Class of 2022",
        "Class of 2023" : "Class of 2023",
        "Selection Committee" : "Nominating Committee",
        "Press & Media" : "Press & Media",
        "About MPB" : "About MPB",
        "Privacy Policy" : "Privacy Policy",
        "Terms & Conditions" : "Terms & Conditions",
        "Hello" : "Howdy",
        // general
        "View the class of 2020" : "View the class of 2020",
        "View the class of 2022" : "View the class of 2022",
        "View Class of 2021" : "View Class of 2021",
        "View Class of 2022" : "View Class of 2022",
        "Learn more" : "Learn more",
        "Submitting" : "Submitting",
        "Try again" : "Try again",
        "We do apologise but something seems to have gone wrong" : "We do apologise but something seems to have gone wrong",
        // cookies
        "cookie_msg" : "This website used cookies to give you the best experience, please choose the cookies you are happy to accept and submit your preferences",
        "Accept all" : "Accept all",
        "Manage cookies" : "Manage cookies",
        "Decline" : "Decline",
        "Necessary" : "Necessary",
        "Preferences" : "Preferences",
        "Statistics" : "Statistics",
        "Marketing" : "Marketing",
        // Countdown
        "Voting opens in" : "Voting opens in",
        "days" : "days",
        "hours" : "hours",
        "minutes" : "minutes",
        "seconds" : "seconds",
        // categories / class of 2020
        "Cast your vote" : "Cast your vote",
        "voted" : "voted",
        "nominees" : "nominees",
        "vote for" : "vote for",
        "selected" : "selected",
        "details" : "details",
        "show" : "show",
        "2020 inductees" : "2020 inductees",
        "learn more" : "learn more",
        "inductee" : "inductee",
        // Newsletter
        "sign up" : "subscribe",
        "your email" : "Your email",
        "select your country" : "Select your country",
        "Select your country" : "Select your country",
        "Please enter your email" : "Please enter your email",
        "Please select your country" : "Please select your country",
        "Please agree to our privacy policy" : "Please agree to our privacy policy",
        "Please agree to our" : "Please agree to our",
        "I have read and accepted the" : "I have read and accepted the",
        "gelesen und akzeptiere diese" : "",
        // Language Selector
        "United States" : "United States",
        "United Kingdom" : "United Kingdom",
        "Canada" : "Canada",
        "South America" : "South America",
        "Other" : "Other",
        "usa" : "usa",
        "uk" : "uk",
        "germany" : "germany",
        "Germany" : "Germany",
        "france" : "france",
        "France" : "France",
        "eu" : "eu",
        // Cart
        "out of" : "out of",
        "votes" : "votes",
        "vote" : "vote",
        "edit votes" : "Edit Votes",
        "cast ballot" : "cast ballot",
        "Your Votes" : "Your Votes",
        "Last chance to cast your remaining votes" : "Last chance to select nominees in remaining categories",
        "You’re voting for the" : "You’re voting for the",
        "Keep Voting" : "Keep Voting",
        "Submit Votes" : "Submit Votes",
        "Submit your votes" : "Provide your information to cast your ballot",
        "Oops, something went wrong" : "Oops, something went wrong",
        "Your votes have been recorded" : "Thank you, You have cast your votes for the Class of 2023.",
        "Submitting your votes" : "Submitting your votes",
        "First Name" : "First Name",
        "Second Name" : "Second Name",
        "Email Address" : "Email Address",
        "Please enter your first name" : "Please enter your first name",
        "Please enter your second name" : "Please enter your second name",
        "Please enter your email" : "Please enter your email",
        "Please enter a valid email" : "Please enter a valid email",
        "Please select your country" : "Please select your country",
        "Please agree to our terms and conditions" : "Please agree to our terms and conditions",
        "Enter Sweepstakes" : "Enter to win a Hasselblad 500CM and more",
        "Receive News and Updates from MPB?" : "Stay up to date about The Photo and Video Kit Hall of Fame, get regular offers on used kit from MPB and read the latest content every week.",
        "By clicking submit you are agreeing to our" : "By clicking submit you are agreeing to our",
        "Edit Votes" : "Edit Votes",
        "submit" : "submit",
        "Return to the site" : "Back to home",
        "Explore MPB" : "Explore MPB",
        "You voted successfully on" : "You voted successfully on",
        "Cast your vote for the Class of 2021" : "Voting has closed for the Class of 2023. Learn more about the 2023 nominees",
        "vote now" : "vote now",
        "sweepstakes_text" : "Enter for a chance to win a used Sony A7 III from MPB. Everyone is invited to vote in the The Photo and Video Hall of Fame, but only residents of the United Kingdom (excluding Northern Ireland) can enter to win.",
        "sweepstakes_hyperlink" : "See Terms and Conditions ",
        "I have read and accepted the" : "I have read and accepted the",
        "Share on social media" : "Share on social media",
        "I voted for the best photo and video kit" : "I voted for the best photo and video kit of the digital age at photoandvideokithalloffame.com",
      }
    },
    "de-DE": {
      translations: {
        "Hello" : "Gutentag",
        "Welcome" : "Wilkomen",
        "Home" : "Startseite",
        "Contact" : "Kontakt",
        "2021 Nominees" : "Jahrgang 2021",
        "2023 Nominees" : "Nominierte für 2023",
        "Class of 2020" : "Jahrgang 2020",
        "Class of 2021" : "Jahrgang 2021",
        "Class of 2022" : "Jahrgang 2022",
        "Class of 2023" : "Jahrgang 2023",
        "Selection Committee" : "Nominierungsausschuss",
        "Press & Media" : "Presse und Media",
        "Privacy Policy" : "Datenschutzerklärung",
        "Terms & Conditions" : "Allgemeine Geschäftsbedingungen",
        "About MPB" : "Über MPB",
        // general
        "View the class of 2020" : "Entdecke den Jahrgang 2020",
        "View the class of 2022" : "Entdecke den Jahrgang 2022",
        "View the class of 2023" : "Entdecke den Jahrgang 2023",
        "View Class of 2021" : "Entdecke den Jahrgang 2021",
        "View Class of 2022" : "Entdecke den Jahrgang 2022",
        "View Class of 2023" : "Entdecke den Jahrgang 2023",
        "Learn more" : "Mehr erfahren",
        "Submitting" : "Submitting",
        "Try again" : "Try again",
        "We do apologise but something seems to have gone wrong" : "Wir entschuldigen uns, aber irgendetwas scheint schief gelaufen zu sein",
        // cookies
        "cookie_msg" : "Diese Webseite verwendet Cookies, um dir das beste Erlebnis zu bieten. Bitte wähle die Cookies aus, die du akzeptieren möchtest und bestätige deine Präferenzen.",
        "Accept all" : "Alle akzeptieren",
        "Manage cookies" : "Cookies verwalten",
        "Decline" : "Ablehnen",
        "Necessary" : "Notwendig",
        "Preferences" : "Einstellungen",
        "Statistics" : "Statistiken",
        "Marketing" : "Marketing",
        // Countdown
        "Voting opens in" : "Abstimmung öffnet in",
        "days" : "tage",
        "hours" : "stunde",
        "minutes" : "minuten",
        "seconds" : "sekunden",
        // categories / class of 2020
        "Cast your vote" : "Jetzt abstimmen",
        "voted" : "gewählt",
        "nominees" : "nominierte",
        "vote for" : "stimmen für",
        "selected" : "selected",
        "details" : "Einzelheiten",
        "show" : "show",
        "2020 inductees" : "2020 neuzugang",
        "learn more" : "Erfahre mehr",
        "inductee" : "neuzugang",
        // Newsletter
        "sign up" : "abonnieren",
        "your email" : "Deine E-Mail-Adresse",
        "select your country" : "Wähle dein Land",
        "Select your country" : "Wähle dein Land",
        "Please enter your email" : "Bitte geben Sie ihre E-Mail-Adresse ein",
        "Please select your country" : "Bitte wählen Sie Ihr Land",
        "Please agree to our privacy policy" : "Bitte stimmen Sie unserer Datenschutzerklärung zu",
        "Please agree to our" : "Bitte stimmen Sie unseren zu",
        "I have read and accepted the" : "Ich habe die",
        "gelesen und akzeptiere diese" : "gelesen und akzeptiere diese",
        // Language Selector
        "United States" : "Vereinigte Staaten",
        "United Kingdom" : "Vereinigtes Königreich",
        "Canada" : "Kanada",
        "South America" : "Südamerika",
        "Other" : "Andere",
        "usa" : "usa",
        "uk" : "uk",
        "germany" : "deutschland",
        "Germany" : "Deutschland",
        "france" : "frankreich",
        "France" : "Frankreich",
        "eu" : "eu",
        // Cart
        "out of" : "von",
        "votes" : "Stimmen",
        "vote" : "abstimmung",
        "cast ballot" : "stimmzettel abgeben",
        "Your Votes" : "Deine Stimmen",
        "Last chance to cast your remaining votes" : "Letzte Chance um Ihre restlichen Stimmen abzugeben.",
        "You’re voting for the" : "Sie stimmen für das",
        "Keep Voting" : "Stimmen weiter ab",
        "Submit Votes" : "Stimmen abgeben",
        "Submit your votes" : "Gebe Deine Informationen an, um Deine Stimme abzugeben",
        "Oops, something went wrong" : "Ups! Irgendwas lief schief",
        "Your votes have been recorded" : "Vielen Dank! Du hast deine Stimmen für die Class of 2023 abgegeben.",
        "Submitting your votes" : "Senden Sie Deine Stimmen",
        "First Name" : "Vorname",
        "Second Name" : "Nachname",
        "Email Address" : "Email Adresse",
        "Please enter your first name" : "Bitte geben Sie Ihren Vornamen ein",
        "Please enter your second name" : "Bitte geben Sie Ihren Nachnamen ein",
        "Please enter your email" : "Bitte geben Sie ihre E-Mail-Adresse ein",
        "Please enter a valid email" : "Bitte geben Sie eine gültige Email-Adresse ein",
        "Please select your country" : "Bitte wählen Sie Ihr Land",
        "Please agree to our terms and conditions" : "Bitte stimmen Sie unseren AGB zu",
        "Enter Sweepstakes" : "Gewinne eine Hasselblad 500CM und mehr",
        "Receive News and Updates from MPB?" : "Bleibe auf dem Laufenden über The Photo and Video Kit Hall of Fame, erhalte regelmäßig Angebote für gebrauchte Fotoausrüstung von MPB und lese jede Woche die neuesten Inhalte.",
        "By clicking submit you are agreeing to our" : "Indem Sie auf Senden klicken, stimmen Sie unseren",
        "edit votes" : "Stimmen bearbeiten",
        "submit" : "Senden",
        "Return to the site" : "Zurück zur Website",
        "Explore MPB" : "MPB entdecken",
        "You voted successfully on" : "Sie haben erfolgreich abgestimmt für",
        "Cast your vote for the Class of 2021" : "Die Abstimmung ist geschlossen für die Class of 2021 Erfahre mehr über die nominierten von 2021",
        "vote now" : "Jetzt abstimmen",
        "sweepstakes_text" : "Nimm an der Verlosung einer gebrauchten Sony A7 III von MPB. Jeder ist eingeladen, in der The Photo and Video Hall of Fame abzustimmen, aber nur Einwohner von Deutschland (Schweiz und Österreich) können am Gewinn teilnehmen.",
        "sweepstakes_hyperlink" : "Siehe Geschäftsbedingungen",
        "I have read and accepted the" : "Ich habe die",
        "Share on social media" : "Teilen auf Social Media",
        "I voted for the best photo and video kit" : "Ich habe auf kithallfofame.com für das beste Foto- und Video-Kit des digitalen Zeitalters gestimmt",
      }
    },
    "fr-FR": {
      translations: {
        // Nav
        "Home" : "Page d'accueil",
        "Contact" : "Contact",
        "2021 Nominees" : "Nominés de 2021",
        "2023 Nominees" : "Nominés de 2023",
        "Class of 2020" : "Promotion 2020",
        "Class of 2021" : "Promotion 2021",
        "Class of 2022" : "Promotion 2022",
        "Class of 2023" : "Promotion 2023",
        "View Class of 2020" : "Voir la Promotion 2020",
        "View Class of 2021" : "Voir la Promotion 2021",
        "View Class of 2022" : "Voir la Promotion 2022",
        "View Class of 2023" : "Voir la Promotion 2023",
        "Selection Committee" : "Comité de nomination",
        "Press & Media" : "Press & Media",
        "About MPB" : "À Propos de mpb",
        "Privacy Policy" : "Politique De Confidentialité",
        "Terms & Conditions" : "Conditions Générales",
        "Hello" : "Howdy",
        // general
        "View the class of 2020" : "Voir la classe de 2020",
        "View the class of 2022" : "Voir la classe de 2022",
        "Learn more" : "Apprendre encore plus",
        "Submitting" : "Submitting",
        "Try again" : "Try again",
        "We do apologise but something seems to have gone wrong" : "Nous nous excusons mais quelque chose semble avoir mal tourné",
        // cookies
        "cookie_msg" : "Ce site Web utilise des cookies pour vous offrir la meilleure expérience, veuillez choisir les cookies que vous êtes heureux d'accepter et soumettre vos préférences",
        "Accept all" : "Accepter tout",
        "Manage cookies" : "Gérer les cookies",
        "Decline" : "Déclin",
        "Necessary" : "Nécessaire",
        "Preferences" : "Préférences",
        "Statistics" : "Statistiques",
        "Marketing" : "Commercialisation",
        // Countdown
        "Voting opens in" : "Le vote s'ouvre dans",
        "days" : "journées",
        "hours" : "heures",
        "minutes" : "minutes",
        "seconds" : "seconds",
        // categories / class of 2020
        "Cast your vote" : "Vote dès maintenant",
        "voted" : "voté",
        "nominees" : "les nominés",
        "vote for" : "voter pour",
        "selected" : "selected",
        "details" : "détails",
        "show" : "show",
        "2020 inductees" : "2020 intronisé",
        "learn more" : "learn more",
        "inductee" : "intronisé",
        // Newsletter
        "sign up" : "s'abonner",
        "your email" : "votre e-mail",
        "select your country" : "sélectionnez votre pays",
        "Select your country" : "sélectionnez votre pays",
        "Please enter your email" : "Veuillez entrer votre email",
        "Please select your country" : "S'il vous plaît sélectionnez votre pays",
        "Please agree to our privacy policy" : "Veuillez accepter notre politique de confidentialité",
        "Please agree to our" : "Veuillez accepter notre",
        "I have read and accepted the" : "J'ai lu et j'accepte les",
        // Language Selector
        "United States" : "États Unis",
        "United Kingdom" : "Royaume-Uni",
        "Canada" : "Canada",
        "South America" : "Amérique du Sud",
        "Other" : "Autre",
        "usa" : "usa",
        "uk" : "uk",
        "germany" : "allemagne",
        "Germany" : "Allemagne",
        "france" : "france",
        "France" : "France",
        "eu" : "ue",
        // Cart
        "out of" : "sur",
        "votes" : "voix",
        "vote" : "vote",
        "cast ballot" : "bulletin de vote",
        "Your Votes" : "Vos votes",
        "Last chance to cast your remaining votes" : "Dernière chance de voter.",
        "You’re voting for the" : "Vous votez pour le",
        "Keep Voting" : "Continuez à voter",
        "Submit Votes" : "Soumettre des votes",
        "Submit your votes" : "Fournissez les informations suivantes avant de voter",
        "Oops, something went wrong" : "Oups, quelque chose s'est mal passé",
        "Your votes have been recorded" : "Merci, Vous avez voté pour le Hall of Fame du kit photo et vidéo de cette année.",
        "Submitting your votes" : "Soumettre vos votes",
        "First Name" : "Prénom",
        "Second Name" : "Nom",
        "Email Address" : "Adresse e-mail",
        "Please enter your first name" : "Entrez votre prénom s'il vous plait",
        "Please enter your second name" : "Veuillez entrer votre deuxième nom",
        "Please enter your email" : "Veuillez entrer votre email",
        "Please enter a valid email" : "Veuillez entrer un email valide",
        "Please select your country" : "S'il vous plaît sélectionnez votre pays",
        "Please agree to our terms and conditions" : "Veuillez accepter nos termes et conditions",
        "Enter Sweepstakes" : "Participez pour gagner un Hasselblad 500CM et bien plus encore",
        "Receive News and Updates from MPB?" : "Restez informé du Hall of Fame du kit photo et vidéo, recevez des offres régulières sur les kits d'occasion de MPB et lisez les nouveaux contenus ajoutés chaque semaine.",
        "By clicking submit you are agreeing to our" : "En cliquant sur soumettre, vous acceptez notre",
        "edit votes" : "Modifier les votes",
        "submit" : "Soumettre et voter",
        "Return to the site" : "Retour à la page d'accueil",
        "Explore MPB" : "Découvrez MPB",
        "You voted successfully on" : "Vous avez voté avec succès sur",
        "Cast your vote for the Class of 2021" : "Voting has closed for the Class of 2021. Learn more about the 2021 nominees",
        "vote now" : "Votez maintenant",
        "sweepstakes_text" : "Participe pour avoir une chance de gagner un Sony A7 III d'occasion de chez MPB. Tout le monde est invité à voter pour le Panthéon Hall of Fame de la photo et de la vidéo, mais seuls les personnes résidant en France métropolitaine peuvent participer.",
        "sweepstakes_hyperlink" : "Voir les conditions générales ",
        "I have read and accepted the" : "J'ai lu et j'accepte les",
        "Share on social media" : "Partagez sur les réseaux sociaux",
        "I voted for the best photo and video kit" : "J'ai voté pour le Hall of Fame du kit photo et vidéo",
      }
    },
    en: {
      translations: {
        "Hello" : "Hello (en)",
      }
    },
  },
  fallbackLng: "en-US",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
