

// Start Imports
import React from "react";
//import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import API from '../api';
import {
  Box
} from 'grommet';
import PageWrap from "../components/PageWrap";
import { shuffleArray } from '../utils/Misc';
import CategoriesComponent from '../components/Categories';
// End Imports

class Categories extends React.Component {

  state = {
    categories: [],
    countdownDate: false,
    showCountdown: false,
    countdownText: "",
  }

  componentDidMount(){
    this.loadCategories();
  }

  loadCategories = () => {
    // Get Categories
    API.getCategories(this.props.i18n).then( (res) => {

      // Countdown Date
      if (this.props.field && this.props.field.hasOwnProperty('fields') && this.props.field.fields.hasOwnProperty('countdownDate')){
        var countdownDate = this.props.field.fields.countdownDate;
      } else {
        var countdownDate = false;
      }

      // Show Countdown
      if (this.props.field && this.props.field.hasOwnProperty('fields') && this.props.field.fields.hasOwnProperty('showCountdown')){
        var showCountdown = this.props.field.fields.showCountdown;
      } else {
        var showCountdown = false;
      }

      // Countdown Text
      if (this.props.field && this.props.field.hasOwnProperty('fields') && this.props.field.fields.hasOwnProperty('countdownText')){
        var countdownText = this.props.field.fields.countdownText;
      } else {
        var countdownText = "";
      }

      // Show Voting buttons
      if (this.props.field && this.props.field.hasOwnProperty('fields') && this.props.field.fields.hasOwnProperty('allowVoting')){
        var allowVoting = this.props.field.fields.allowVoting;
      } else {
        var allowVoting = false;
      }

      var categories = res.items
      // Move prtoduct references inline
      categories.forEach( (category) => {
        var products = []
        if (category.fields.hasOwnProperty('products')) {
          category.fields.products.forEach( (product) => { // Loop over products in cateogry
            var product_id = product.sys.id
            res.includes.Entry.forEach( (include) => { // Loop over all includes.Entry and find product
              if (include.sys.id === product_id){
                // Add to products array
                products.push(include.fields)
              }
            })
          })
        }
        category.products = shuffleArray(products)
      })

      this.setState({
        categories: shuffleArray(categories),
        countdownDate: countdownDate,
        showCountdown: showCountdown,
        countdownText: countdownText,
        allowVoting: allowVoting,
      })

    })
    .catch(console.error)
  }


  render() {
    const { match, t, i18n } = this.props;

    return (
      <Box id="page">
      {/**
        <h2>This content comes from Contentful</h2>
        <p>Current locale: {i18n.language}</p>
      **/}

      <PageWrap content={this.state.categories}>

        <CategoriesComponent
          t={t}
          i18n={i18n}
          match={match}
          countdownDate={this.state.countdownDate}
          categories={this.state.categories}
          showCountdown={this.state.showCountdown}
          countdownText={this.state.countdownText}
          allowVoting={this.state.allowVoting}
        />

      </PageWrap>

        {/**
        <ul>
          <li>
            <Link to={`${match.url}/rendering`}>
              <Trans>Rendering with React</Trans>
            </Link>
          </li>
          <li>
            <Link to={`${match.url}/components`}>Components</Link>
          </li>
          <li>
            <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
          </li>
        </ul>


        <Route path={`${match.url}/:categoryId`} component={Category} />
        <Route
          exact
          path={match.url}
          render={() => <h3>Please select a category.</h3>}
        />

        **/}
      </Box>
    );
  }
}

export default withTranslation("translations")(Categories);
